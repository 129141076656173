@import url("https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300&display=swap");

@-webkit-keyframes enter {
  0% {
    opacity: 0;
    top: -10px;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: 10px;
  }
}
@keyframes enter {
  0% {
    opacity: 0;
    top: -10px;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: 10px;
  }
}
@-moz-keyframes enter {
  0% {
    opacity: 0;
    top: -10px;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: 10px;
  }
}
html {
  cursor: none;
}

body {
  font-family: "Sansita Swashed", cursive, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  cursor: none;
  background: black;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27.5px;
  margin-top: -27.5px;
}

.square {
  background: rgb(206, 204, 204);
  width: 15px;
  height: 15px;
  float: left;
  top: -10px;
  margin-right: 5px;
  margin-top: 5px;
  position: relative;
  opacity: 0;
  -webkit-animation: enter 6s infinite;
  animation: enter 6s infinite;
}

.enter {
  top: 0px;
  opacity: 1;
}

.square:nth-child(1) {
  -webkit-animation-delay: 1.8s;
  -moz-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.square:nth-child(2) {
  -webkit-animation-delay: 2.1s;
  -moz-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.square:nth-child(3) {
  -webkit-animation-delay: 2.4s;
  -moz-animation-delay: 2.4s;
  animation-delay: 2.4s;
  /* background: #fdc96f; */
}

.square:nth-child(4) {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.square:nth-child(5) {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.square:nth-child(6) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.square:nth-child(8) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.square:nth-child(9) {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.clear {
  clear: both;
}

.last {
  margin-right: 0;
}
